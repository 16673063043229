* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* color: aquamarine; */
  /* background: linear-gradient(to top, #09203f 0%, #537895 100%); */
}

.main-container {
  background-image: linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%);
}

/* Header start */

.header-container {
  /* background: #537895; */
  width: 100%;
  height: 60px;
  background-image: linear-gradient(-20deg, #e9defa 0%, #fbfcdb 0%);
  border-bottom: 1px solid black;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.header-inner {
  width: 85%;
  height: 60px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  font-size: 24px;
  font-weight: 550;
}
.logo span svg {
  width: 22px;
}

.navbar {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-size: 18px;
  font-weight: 600;
}

.navbar .svg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid #e8630a;
}

.navbar .svg svg {
  width: 18px;
}

/* Header end */

/* Home Section Start */

.home-container {
  width: 100%;
  height: 600px;
}

.home-inner {
  width: 85%;
  height: 600px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.home-title {
  width: 400px;
  text-align: start;
}

.home-title h3 {
  font-family: 'Poppins', sans-serif;
  font-size: 38px;
  font-weight: 500;
  text-transform: capitalize;
}

.home-btn {
  margin-top: 20px;
  height: 30px;
  width: 115px;
  background: #e8630a;
  border: none;
}

.home-btn a {
  text-decoration: none;
  text-transform: capitalize;
  font-family: 'Poppins', sans-serif;
  color: #fff;
}

.home-image img {
  width: 100%;
}
/* Home Section end */

/* Print section start */

.print-container {
  width: 100%;
  height: 400px;
  margin-top: 100px;
}

.header-print {
  width: 300px;
  margin: 0 auto;
  text-transform: uppercase;
  font-family: 'Zilla Slab', serif;
  font-style: italic;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #e8630a;
}

.print-inner {
  width: 85%;
  margin: 0 auto;
  display: flex;
  gap: 100px;
  justify-content: center;
  align-items: center;
}

.print-img {
  width: 400px;
}

.print-img img {
  width: 100%;
}

.print-title {
  width: 400px;
}

.print-title ul {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.print-title ul li {
  list-style-type: none;
  margin-bottom: 8px;
  font-family: 'Zilla Slab', serif;
  font-size: 24px;
  font-style: italic;
  font-weight: 500;
  cursor: pointer;
  width: 150px;
  /* margin-left: -150px; */
}

/* Print section end */

/* About section start */

.about-container {
  width: 100%;
  height: 200px;
}

.about-inner {
  width: 85%;
  margin: 0 auto;
}

.about-header {
  width: 85%;
  margin: 0 auto;
  text-transform: uppercase;
  font-family: 'Zilla Slab', serif;
  font-style: italic;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #e8630a;
}

.about-dsc {
  width: 65%;
  margin: 0 auto;
  margin-top: 20px;
  text-align: center;
  font-family: 'Zilla Slab', serif;
  font-style: italic;
  font-weight: 500;
  font-size: 20px;
}

/* About section end */

/* Uty section start */

.uty-container {
  width: 100%;
  height: 550px;
}

.uty-header {
  width: 85%;
  margin: 0 auto;
  text-transform: uppercase;
  font-family: 'Zilla Slab', serif;
  font-style: italic;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  color: #e8630a;
}

.uty-inner {
  width: 85%;
  margin: 0 auto;
  display: flex;
  gap: 100px;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.uty-img {
  width: 400px;
}

.uty-img img {
  width: 100%;
}

.uty-title {
  width: 400px;
}

.uty-title ul {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.uty-title ul li {
  list-style-type: none;
  margin-bottom: 8px;
  font-family: 'Zilla Slab', serif;
  font-size: 24px;
  font-style: italic;
  font-weight: 500;
  cursor: pointer;
  text-align: start;
  width: auto;
  text-transform: capitalize;
  /* margin-left: -150px; */
}

/* Uty section end */

/* lang sectiın start */
.lang-container {
  width: 100%;
  height: 400px;
  margin-top: 20px;
  margin-bottom: 20px;
}
/* lang sectiın end */

/* Deliver section start */

.deliver-container {
  width: 100%;
  height: 300px;
}

.deliver-inner {
  width: 85%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
}

.deliver-img {
  width: 400px;
}

.deliver-img img {
  width: 100%;
}

.deliver-title {
  width: 400px;
}

.deliver-title h3 {
  list-style-type: none;
  margin-bottom: 8px;
  margin-top: 10px;
  font-family: 'Zilla Slab', serif;
  font-size: 24px;
  font-style: italic;
  font-weight: 600;
  cursor: pointer;
  width: 450px;
  text-transform: capitalize;
}

/* Deliver section  end */

/* ayu sectşon start */
.ayu-img {
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ayu-img img {
  width: 90%;
}

/* ayu sectşon end */

/* revievs section start */
.revievs-container {
  width: 100%;
  height: 520px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.revievs-container iframe {
  width: 85%;
  height: 520px;
}

/* revievs section end */

/* footer section start */
.footer-container {
  background-image: linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%);

  width: 100%;
  height: 60px;
  border-top: 1px solid black;
}

.footer-inner {
  width: 85%;
  height: 60px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  font-size: 16px;
  font-weight: 550;
}
.footer-logo span svg {
  width: 16px;
}

/* footer section end */

/* map section start */
.map-container {
  width: 100%;
  height: 350px;
}

.map {
  width: 100%;
  height: 350px;
}
/* map section end */

/* whatsapp section start */
.whatsapp {
  position: fixed;
  bottom: 50px;
  right: 50px;
  width: 50px;
  height: 50px;
}

.whatsapp a img {
  width: 50px;
  height: 50px;
}

/* Whatsapp section end */

/* mobile version start */

@media only screen and (max-width: 600px) {
  .header-container {
    /* background: #537895; */
    width: 100%;
    height: 40px;
    background-image: linear-gradient(-20deg, #e9defa 0%, #fbfcdb 0%);
    border-bottom: 1px solid black;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .header-inner {
    width: 85%;
    height: 40px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
    font-size: 16px;
    font-weight: 550;
  }
  .logo span svg {
    width: 14px;
  }

  .navbar {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-size: 16px;
    font-weight: 600;
  }

  .navbar .svg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 2px solid #e8630a;
  }

  .navbar .svg svg {
    width: 10px;
  }

  /* Home Section Start */

  .home-container {
    width: 100%;
    height: 600px;
  }

  .home-inner {
    width: 85%;
    height: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: center;
  }

  .home-title {
    width: 300px;
    text-align: center;
  }

  .home-title h3 {
    font-family: 'Poppins', sans-serif;
    font-size: 35px;
    font-weight: 500;
    text-transform: capitalize;
  }

  .home-btn {
    margin-top: 20px;
    height: 30px;
    width: 115px;
    background: #e8630a;
    border: none;
    border-radius: 8px;
  }

  .home-btn a {
    text-decoration: none;
    text-transform: capitalize;
    font-family: 'Poppins', sans-serif;
    color: #fff;
  }

  .home-image img {
    width: 100%;
  }
  /* Home Section end */

  /* About section start */

  .about-container {
    width: 100%;
    height: 270px;
    margin-top: 20px;
  }

  .about-inner {
    width: 85%;
    margin: 0 auto;
  }

  .about-header {
    width: 85%;
    margin: 0 auto;
    text-transform: uppercase;
    font-family: 'Zilla Slab', serif;
    font-style: italic;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #e8630a;
  }

  .about-dsc {
    width: 85%;
    margin: 0 auto;
    margin-top: 20px;
    text-align: center;
    font-family: 'Zilla Slab', serif;
    font-style: italic;
    font-weight: 500;
    font-size: 17px;
  }

  /* About section end */

  /* Print section start */

  .print-container {
    width: 100%;
    height: 620px;
    margin-top: 100px;
  }

  .header-print {
    width: 300px;
    margin: 0 auto;
    text-transform: uppercase;
    font-family: 'Zilla Slab', serif;
    font-style: italic;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #e8630a;
  }

  .print-inner {
    width: 85%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
  }

  .print-img {
    width: 400px;
  }

  .print-img img {
    width: 100%;
  }

  .print-title {
    width: 400px;
  }

  .print-title ul {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .print-title ul li {
    list-style-type: none;
    margin-bottom: 8px;
    font-family: 'Zilla Slab', serif;
    font-size: 24px;
    font-style: italic;
    font-weight: 500;
    cursor: pointer;
    width: 150px;
    text-align: center;
    /* margin-left: -150px; */
  }

  /* Print section end */

  .uty-container {
    width: 100%;
    height: 720px;
  }

  .uty-header {
    width: 85%;
    margin: 0 auto;
    text-transform: uppercase;
    font-family: 'Zilla Slab', serif;
    font-style: italic;
    font-size: 18px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    color: #e8630a;
  }

  .uty-inner {
    width: 85%;
    margin: 0 auto;
    display: flex;
    gap: 50px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin-top: 50px; */
  }

  .uty-img {
    width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .uty-img img {
    width: 90%;
  }

  .uty-title ul {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .uty-title ul li {
    list-style-type: none;
    margin-bottom: 8px;
    font-family: 'Zilla Slab', serif;
    font-size: 24px;
    font-style: italic;
    font-weight: 500;
    cursor: pointer;
    text-align: start;
    width: auto;
    text-transform: capitalize;
    /* margin-left: -150px; */
  }

  .deliver-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
  }

  .deliver-title h3 {
    width: 90%;
    font-size: 18px;
    font-weight: 700;
  }

  /* Uty section end */

  .ayu-img {
    width: 85%;
  }

  .lang-container {
    height: 500px;
    margin-top: 20px;
  }

  /* whatsapp section start */
  .whatsapp {
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 35px;
    height: 35px;
  }

  .whatsapp a img {
    width: 35px;
    height: 35px;
  }

  /* Whatsapp section end */
}
/* mobile version end */

/* tablet version end */

/* tablet version end */
